body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: #fafafa;
  overflow-x: hidden;
}
/* TODO who added this? Why not inside of component? */
.flag-icon {
  padding-right: 10px;
}
/* ugly globalization of AspectFaces here but unfortunately Material-UI labels take 
 * strings only and this is the only way to add colored asterisks
 */
.af-required label::after {
  content: " *";
  color: #f44336;
}
.af-needed label::after {
  content: " *";
}

a:hover {
  color: #2172dd;
}

a {
  color: #1e3c64;
}
